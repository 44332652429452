.scrallable-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--unit-size-x);
    overflow-y: auto;
    position: relative;
}

.scrallable-section > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    padding: var(--unit-size-4x);
}


.scrallable-section--div-mid {
    justify-content: center;
}

.scrallable-section--div-top {
    position: absolute;
    top: 0;
}