.command-section-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--unit-size-3x);
    
    width: 100%;
}

.command-button--activated {
    background-color: green;
}

.command-button--pending {
    background-color: rgb(36, 131, 148);
}