.layout {
    width: 100vw;
}

.nav-bar {
    background-color: var(--primary-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: var(--unit-size-2x) var(--unit-size-4x);

}

.nav-bar * {
    color: white;
}

.nav-bar a {
    text-decoration: none;
}

.nav-bar > button {
    background-color: transparent;
    padding: var(--unit-size-x) var(--unit-size-4x);
    border: none;
}

.nav-bar > button:hover {
    opacity: 50%;
    cursor: pointer;
}

.main-frame {
    height: calc(100vh - 56px);
}