.telemetry-page-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    margin: var(--unit-size-4x);
}

.telemetry-page-container section {
    border: 1px solid black;
}

.telemetry-page-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    margin: var(--unit-size-4x);
}

.telemetry-page-container section {
    border: 1px solid black;
}

.telemetry-grid-section--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--unit-size-x) 0;
    width: 50%;
}

.telemetry-grid-section--item p {
    font-size: 1.25rem;
}

.telemetry-grid-section--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--unit-size-x) 0;
}

.telemetry-grid-section--item p {
    font-size: 1.25rem;
}